import React from 'react';
import {useIntl} from 'gatsby-plugin-intl';
import {Button, Typography} from 'antd';
import styles from './ApplyJobBlock.module.less';
import {Link} from 'gatsby';

const ApplyJobBlock = () => {
    const {locale, formatMessage} = useIntl();
    const {Title, Text} = Typography;

    return (
        <div className={styles.block}>
            <Title level={4} className={styles.title}>{formatMessage({id: 'apply-job.title'})}</Title>
            <Text className={styles.text}>{formatMessage({id: 'apply-job.text'})}</Text>
            <Link to={`/${locale}/contact`} state={{subject: formatMessage({id: 'contact-form.subject.application'})}}>
                <Button type="primary">
                    {formatMessage({id: 'apply-job.button'})}
                </Button>
            </Link>
        </div>
    );
};

export default ApplyJobBlock;
