import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import Helmet from 'react-helmet';
import styles from '../components/category-page/CategoryPage.module.less'
import VacancyCategoryEmptyPanel from '../components/category-empty-panel/VacancyCategoryEmptyPanel';
import {useIntl} from 'gatsby-plugin-intl';
import VacancyCategoryLink from '../components/category-link/VacancyCategoryLink';
import {Row} from 'antd';
import VisibilitySensor from '../components/VisibilitySensor';
import VacancyPreview from '../components/vacancy-preview/VacancyPreview';
import ApplyJobBlock from '../components/apply-job-block/ApplyJobBlock';
import JobsFAQBlock from '../components/jobs-faq-block/JobsFAQBlock';
import JobsFAQBlockMobile from '../components/jobs-faq-block-mobile/JobsFAQBlockMobile';

export default function Template({data, pageContext}) {
    const {formatMessage} = useIntl();
    const {category} = pageContext;
    const jobs = data.allContentfulVacancy.edges
        .filter(({node: job}) => category !== null ? job.category.slug === category.slug : true)
        .map(({node: job}) => job);
    const categories = data.allContentfulVacancyCategory.edges
        .map(({node}) => node);

    return (
        <Layout data={data}>
            <Helmet>
                <title>{formatMessage({id: 'jobs.page-title'})}</title>
                <meta name="description" content={formatMessage({id: 'jobs.page-description'})} />
            </Helmet>
            <div className={styles.substrate} style={{backgroundColor: category?.color || '#D9F3F9'}}>
                <h1
                    className={`${styles.title} show-lg-down`}
                    style={{color: category?.color ? '#fff' : ' #272727'}}
                >
                    {formatMessage({id: 'jobs.title'})}
                </h1>
            </div>
            <div className={styles.section}>
                <h1 className={`${styles.title} show-lg-up`}>{formatMessage({id: 'jobs.title'})}</h1>
                <p className={styles.text}>{formatMessage(
                    {id: 'jobs.text'},
                    {br: <br />}
                )}</p>
                <div className={`${styles.linkContainer} align-center`}>
                    {[null].concat(categories).map((c) => (
                        <VacancyCategoryLink
                            key={c?.id || 'default'}
                            category={c}
                            activeCategory={category} />
                    ))}
                </div>
                {jobs.length === 0 && <VacancyCategoryEmptyPanel activeCategory={category} />}
                {jobs.length > 0 && (
                    <Row>
                        {jobs.map(post => <VacancyPreview key={post.id} post={post} fullWidth />)}
                    </Row>
                )}
                <VisibilitySensor>
                    <div className={styles.applyBlock}>
                        <ApplyJobBlock />
                    </div>
                </VisibilitySensor>
            </div>
            <VisibilitySensor>
                <div className="show-lg-down">
                    <JobsFAQBlockMobile />
                </div>
                <div className="show-lg-up">
                    <JobsFAQBlock />
                </div>
            </VisibilitySensor>
        </Layout>
    );
};

export const pageQuery = graphql`
  query($locale: String) {
    allContentfulVacancy(filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }) {
      edges {
        node {
          id
          title
          slug
          employmentType
          description { raw }
          category {
            slug
            color
           }
        }
      }
    }
    allContentfulVacancyCategory(sort: {fields: createdAt, order: ASC}, filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }) {
      edges {
        node {
          id
          title
          slug
          color
        }
      }
    }
    allContentfulArticle(filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }) {
      edges {
        node {
          title
          slug
          excerpt
          category {
            color
            slug
          }
        }
      }
    }
     allContentfulStory(
      sort: {fields: [category___createdAt, createdAt], order: [ASC, DESC]}, 
      filter: {node_locale: {eq: $locale}, slug: {ne: "skip"}}
    ) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`;
